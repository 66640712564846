@font-face {
  font-family: Flama;
  src: url("assets/fonts/Flama Regular.woff");
}
@font-face {
  font-family: Flama-Bold;
  src: url("assets/fonts/Flama-Bold.woff");
}

@font-face {
  font-family: Helvetica;
  src: url("assets/fonts/Helvetica Neue W01 66 Medium It.otf");
}
@font-face {
  font-family: Helveticalight;
  src: url("assets/fonts/Helvetica Neue UltraLight.otf");
}

::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
body {
  margin: 0;
  font-family: Flama;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #8a0303;
  overflow: hidden;
}
html,
body {
  margin: 0;
  padding: 0;
}

code {
  font-family: Flama;
}
.MuiInputBase-formControl {
  color: #000 !important;
  background: #fff;
  // .MuiInputBase-input {
  //     padding: 8px !important;
  // }
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #fff !important;

    border-radius: 0 !important;
  }
}

.MuiMenu-paper {
  .MuiMenuItem-root {
    font-size: 28px;
  }
}
